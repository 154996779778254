module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="container">\r\n        <div class="left">\r\n            <a href="index.html">\r\n                <img src="' +
((__t = ( require('./images/footer-logo.png') )) == null ? '' : __t) +
'" />\r\n            </a>\r\n            <ul>\r\n                <li>联系电话：0532-58820001</li>\r\n                <li>公司地址：青岛市崂山区游云路6号</li>\r\n                <li>邮箱地址： info@greensum.com.cn</li>\r\n                <li>\r\n                    Copyright ©20239001诚信金沙-www.9001.cc|官方网站 All Rights Reserved \r\n                    <a href="https://beian.miit.gov.cn/">鲁ICP09023256号 -2</a>\r\n                </li>\r\n                <li>\r\n                    <a href="https://dongbaqu.com/">网站建设 | 东八区网络</a>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n        <div class="right">\r\n            <ul>\r\n                <li>\r\n                    <div class="pic">\r\n                        <img src="' +
((__t = ( require('./images/ewm1.png') )) == null ? '' : __t) +
'" />\r\n                    </div>\r\n                    <p>公众号二维码</p>\r\n                </li>\r\n                <li>\r\n                    <div class="pic">\r\n                        <img src="' +
((__t = ( require('./images/ewm2.png') )) == null ? '' : __t) +
'" />\r\n                    </div>\r\n                    <p>网站二维码</p>\r\n                </li>\r\n            </ul>\r\n        </div>\r\n    </div>\r\n</footer>\r\n<ul class="right_nav">\r\n    <li>\r\n       <div class="iconBox oln_ser"> \r\n        <img src="' +
((__t = ( require('./images/right_nav1.png') )) == null ? '' : __t) +
'" />\r\n          <h4>在线客服</h4>\r\n       </div>\r\n       <div class="hideBox">\r\n          <div class="hb">\r\n             <h5>在线咨询</h5>\r\n             <div class="qqtalk">\r\n                <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=（你的QQ号码）&site=qq&menu=yes">\r\n                   <span><img src="' +
((__t = ( require('./images/online.png') )) == null ? '' : __t) +
'" alt="点击发消息"\r\n                         title="点击发消息" /></span>\r\n                   <p>点击发消息</p>\r\n                </a>\r\n             </div>\r\n          </div>\r\n       </div>\r\n    </li>\r\n    <li>\r\n        <div class="iconBox">\r\n         <img src="' +
((__t = ( require('./images/right_nav2.png') )) == null ? '' : __t) +
'" />\r\n           <h4>微信公众号</h4>\r\n        </div>\r\n        <div class="hideBox">\r\n           <div class="hb">\r\n              <h5>手机扫一扫打开</h5>\r\n              <img src="' +
((__t = ( require('./images/ewm1.png') )) == null ? '' : __t) +
'" />\r\n           </div>\r\n        </div>\r\n     </li>\r\n    <li>\r\n       <div class="iconBox phe_num">\r\n        <img src="' +
((__t = ( require('./images/right_nav3.png') )) == null ? '' : __t) +
'" />\r\n          <h4>联系电话</h4>\r\n       </div>\r\n       <div class="hideBox">\r\n          <div class="hb">\r\n             <h5>热线电话</h5>\r\n             <p>0532-58820001</p>\r\n          </div>\r\n       </div>\r\n    </li>\r\n    <li>\r\n       <div class="iconBox top">\r\n        <img src="' +
((__t = ( require('./images/right_nav4.png') )) == null ? '' : __t) +
'" />\r\n          <h4>回到顶部</h4>\r\n       </div>\r\n    </li>\r\n </ul>';

}
return __p
}